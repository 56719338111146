import React from "react"

class Terms extends React.Component {
render() {
  return (
    <div>
User-agent: * <br/>Allow: /<br/>Disallow: /your-idea<br/>Disallow: /terms<br/>Disallow: /privacy-policy
    </div>
)
}
}

export default Terms;
